<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('warning')" :isFilter="false">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('warning')" :isFilter="false">
                </HeaderMobile>
            </template>
            <warning-message :warning="this.warning" @approved="approved"></warning-message>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from '@/layouts/AppLayout'
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'


//Other
import qs from "qs";
import WarningMessage from "@/components/page/WarningMessage.vue";
import Base from "@/plugins/Base";

export default {
    name: "Warning",
    components: {
        WarningMessage,
        AppLayout,
        Header,
        HeaderMobile,
    },
    metaInfo() {
        return {
            title: this.$t('warning')
        }
    },
    data() {
        return {
            warning: null
        }
    },
    created() {
        let user = Base.LocalStorage.get("user");
        if(!user || !user.warning){
            this.$router.push('/dashboard');
        }
        this.warning=user.warning;
    },
    methods: {
        approved(){
            this.$store.dispatch('auth/getUser').then(() => {
                let user = Base.LocalStorage.get('user');
                if (!user.warning) {
                    this.$router.push('/dashboard');
                }
                this.warning = user.warning;
            });
        }
    }
}
</script>
