<template>
	<div class="border rounded-sm pl-2 pr-2 pb-2">
		<div class="text-center"><i class="ri-error-warning-line" style="font-size: 48px; line-height: 1.2" /></div>
		<h4 class="text-center">{{ getLocaleText(warning, 'subject') }}</h4>
		<p style="font-size: 16px;" v-html="getLocaleText(warning, 'explanation')"></p>
        <hr>
        <div class="row">
            <div class="col-12 text-center">
                <b-check v-model="approval" size="md">{{$t('i_have_read_understood_and_approve')}}</b-check>
            </div>
            <div class="col-12 d-flex justify-content-center mt-2" v-if="approval">
                <button class="btn btn-success" @click="approve">{{$t('approve')}}</button>
            </div>
        </div>
	</div>
</template>

<script>
import WarningService from "@/services/WarningsService";
import Base from "@/plugins/Base";
	export default {
		props: {
			warning: {
				default: null,
			},
		},
        data(){
            return {
                approval: false
            }
        },
        methods: {
            approve(){
                WarningService.read(this.warning.id).then(response=>{
                    this.$emit('approved');
                }).catch(e=>{
                    this.showErrors(e);
                });
            }
        }
	};
</script>
